<template>
  <div>
    <headers />
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="200px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            class="input"
            placeholder="请输入原密码"
            v-model="form.oldPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            class="input"
            placeholder="请输入新密码"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="请确认新密码" prop="passwordTwo">
          <el-input
            class="input"
            placeholder="请确认新密码"
            v-model="form.passwordTwo"
          ></el-input>
        </el-form-item>

        <el-button class="Btn" @click="uploadPwd">确认修改</el-button>
      </el-form>
    </div>
    <footers />
  </div>
</template> 

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";
import { passwordUpdate } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldPassword: "",
        password: "", //密码
        passwordTwo: "", //密码
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { min: 6, max: 20, message: "密码长度在6~20个数字", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "密码长度在6~20个数字", trigger: "blur" },
        ],
        passwordTwo: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    uploadPwd() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          passwordUpdate(
            this.form
          ).then(async (res) => {
              if(res.code==200){
                this.$message.success('修改成功请重新登录');
                localStorage.removeItem('token');
                this.$router.push({
                  path: "/login",
                });
              }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 1400px;
  height: 577px;
  margin: 0 auto;
  margin-top: 51px;
  margin-bottom: 150px;
  background: #ffffff;
  display: flex;
  padding: 30px 0;
  box-sizing: border-box;
}
.input {
  width: 380px;
}
.Btn {
  width: 120px;
  height: 39px;
  margin-left: 200px;
  background: #00d8c3;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #00d8c3;
  &.is-active,
  &:active {
    border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
    outline: none; /* 移除默认的轮廓线 */
  }
}
</style>